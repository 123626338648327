<template>
<div>

 <form-wizard
      color="#9600ff"
      :title="null"
      :subtitle="null"
      layout="vertical"
      finish-button-text="Submit"
      back-button-text="Previous"
      class="vertical-steps steps-transparent mb-3 friends-tables"
      ref="wizard"
    >
          <!-- Meetings -->
     
      <tab-content  :title="$t('Friends')" icon="feather icon-users">
        <friends  class="mt-2 pt-75" />
      </tab-content>

      <tab-content  :title="$t('All_Users')" icon="feather icon-user-plus">
        <all-users  class="mt-2 pt-75" />
      </tab-content>
    
      <tab-content  :title="$t('Invitation_request')" icon="feather icon-user-check">
        <invitation-request  class="mt-2 pt-75" />
      </tab-content>

      <tab-content  :title="$t('Pending_invitations')" icon="feather icon-user-x">
        <pending-invitation  class="mt-2 pt-75" />
      </tab-content>
      
    </form-wizard>


  </div>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink,  BRow, BCol } from "bootstrap-vue";
import store from "@/store";

import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";


import pendingInvitation from "./user-friend/pendingInvitation.vue";
import allUsers from "./user-friend/allUsers.vue";
import friends from "./user-friend/friends.vue";
import invitationRequest from "./user-friend/invitationRequest.vue";


import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

import axios from "axios";

export default {
  components: {
    FormWizard,
    TabContent,

    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    pendingInvitation,
    allUsers,
    friends,
    invitationRequest,

    BRow,
    BCol
  },
   mounted(){
        this.$refs.wizard.maxStep = 3
        this.$refs.wizard.navigateToTab(0)   
   },
  data() {
    return {
      nb:0,
      titleNumber:"Invitation request"
    }
  },
  async created(){
    this.getNumber()
  },
  methods:{
    getNumber(){
      console.log(this)
          axios
       .get(
        "https://backendapinodejs.timecheckit.com/api/friendRequest?to="+ JSON.parse(localStorage.getItem("userData"))._id
      )
        .then((response) => {
        response.data.data.forEach(element => {
          if(element.state=="onHold"){
            this.nb++
          }
        });
        this.titleNumber=this.$t('Invitation_request') +" ("+ this.nb +")"
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
    }
  }
 };
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
// [dir] .steps-transparent .wizard-tab-content {
//   background: none !important;
//   -webkit-box-shadow: none !important;
//   box-shadow: none !important;
// }
[dir="ltr"] .steps-transparent .wizard-card-footer {
  margin-top:20px ;
  background: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
// [dir] .vue-form-wizard .wizard-tab-content {
//   padding: 10px 1px 15px !important;
// }
.friends-tables .wizard-card-footer{
  display:none!important;
}
</style>
