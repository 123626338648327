<template>
  <div>

   
      <!-- search input -->
        <div class="custom-search d-flex justify-content-start">
          <span class="btn-action-table">
               <b-button
                  variant="gradient-success"
                  class="btn-icon"
                  @click="styleDisplay=false"
                >
                  <!-- @click="DeleteCategory(props.row._id)" -->
                  <feather-icon icon="ListIcon" />
                </b-button>

                <b-button
                  variant="gradient-success"
                  class="btn-icon"
                  @click="styleDisplay=true"
                >
                  <!-- @click="DeleteCategory(props.row._id)" -->
                  <feather-icon icon="CreditCardIcon" />
                </b-button>
          </span>
      </div>

    <all-users-table v-if="styleDisplay==false"></all-users-table>

    <all-users-cards v-if="styleDisplay==true"></all-users-cards>

  </div>
</template>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BForm,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BButton,
  BModal,
  VBModal,
  BCol,
  BContainer,
  BRow,
} from "bootstrap-vue";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";

import allUsersTable from "./all-pending/all-users-table.vue";
import allUsersCards from "./all-pending/all-users-cards.vue";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "axios";

export default {
  components: {
    allUsersTable,
    allUsersCards,
    BForm,
    BCol,
    BContainer,
    BRow,
    BButton,
    BModal,
    VBModal,
    Ripple,
    BCardActions,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdownItem,
    BDropdown,
  },
  data() {
    return {
      dir: false,
      styleDisplay:true
    };
  },
  directives: {
    Ripple,
  },
  
  created() {
  },
  methods: {

  },
};
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
@import "~@radial-color-picker/vue-color-picker/dist/vue-color-picker.min.css";

.btn-delete-all {
  margin-left: 3%;
}
.btn-action-table button {
  margin-left: 10px;
}
.btn-action-table {
  display: flex;
}
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
.pr-0 {
  padding-right: 0 !important;
}
.btn-table-groupe {
  float: right;
}
</style>
