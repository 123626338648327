<template>
  <div>
    <div v-if="rows.length == 0">    {{ $t('No_users') }}</div>
    <div class="row" v-else>
      <div
        class="col-md-3 col-sm-4 user-card-design-col"
        v-for="(person, index) in rows"
        :key="index"
      >
        <div class="user-card-design">
          <b-card
            :img-src="require('@/assets/images/banner/banner-12.jpg')"
            img-alt="Profile Cover Photo"
            img-top
            class="card-profile"
          >
            <div class="profile-image-wrapper">
              <div class="profile-image p-0">
                <b-avatar size="80" variant="light-primary">
                  <feather-icon icon="UserIcon" size="32" />
                </b-avatar>
              </div>
            </div>
            <h3 class="pt-10">{{ person.name }}</h3>
            <h6 class="text-muted" v-if="person.adresse">
              {{ person.adresse.country }} , {{ person.adresse.city }}
            </h6>
            <h6 class="text-muted" v-else>{{ $t('No_adresse') }}</h6>
            <hr class="mb-2" />

            <!-- follower projects rank -->
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <h6 class="text-muted font-weight-bolder">{{ $t('Friends') }}</h6>
                <h3 class="mb-0">{{ person.friendList.length || 0 }}</h3>
              </div>
              <div>
                <h6 class="text-muted font-weight-bolder">{{ $t('Delete_friend') }}</h6>
                <b-button
                  variant="gradient-success"
                  class="btn-icon"
                  @click="addFriend(person._id)"
                >
                  <!-- @click="DeleteCategory(props.row._id)" -->
                  <feather-icon icon="UserPlusIcon" />
                </b-button>
              </div>
            </div>
            <!--/ follower projects rank -->
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BForm,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BButton,
  BModal,
  VBModal,
  BCol,
  BContainer,
  BRow,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";
import { heightTransition } from "@core/mixins/ui/transition";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "axios";

export default {
  components: {
    BForm,
    BCol,
    BContainer,
    BRow,
    BButton,
    BModal,
    VBModal,
    Ripple,
    BCardActions,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdownItem,
    BDropdown,
  },
  data() {
    return {
      rows: [],
      userInfo: {},
    };
  },
  directives: {
    Ripple,
  },
  async created() {
    // await this.getUserInfo()
    await this.getdata();
  },
  methods: {
    // getUserInfo(){

    // },
    async getdata() {
      this.rows = [];
      await axios
        .get("https://backendapinodejs.timecheckit.com/api/users")
        .then((response) => {
          axios
            .get(
              "https://backendapinodejs.timecheckit.com/api/users?id=" +
                JSON.parse(localStorage.getItem("userData"))._id
            )
            .then((res) => {
              this.userInfo = res.data.data;
              console.log("dsdq", this.userInfo);
              if (response.data.data.length > 0) {
                // response.data.data.forEach((element) => {
                //   if (element.isVerified) {
                //     this.rows.push(element);
                //   }
                // });
                this.rows = response.data.data.filter(user => user.isVerified && !this.userInfo.friendList.some(friend => friend._id === user._id));

              }
            });

          // console.log(response.data.data);
          // this.rows = response.data.data;
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    },
    addFriend(id) {
      axios
        .post("https://backendapinodejs.timecheckit.com/api/friendRequest", {
          from: JSON.parse(localStorage.getItem("userData"))._id,
          to: id,
        })
        .then((response) => {
          this.getdata();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Request send`,
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    },
    getImg(person) {
      // if(person.image){
      //   return require(person.image)
      // }else{
      return require(img);
      // }
    },
  },
};
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";

@import "~@radial-color-picker/vue-color-picker/dist/vue-color-picker.min.css";
.pt-10 {
  padding-top: 10px;
}
.btn-delete-all {
  margin-left: 3%;
}
.btn-action-table button {
  margin-left: 10px;
}
.btn-action-table {
  display: flex;
}
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
.pr-0 {
  padding-right: 0 !important;
}
.btn-table-groupe {
  float: right;
}
.user-card-design-col {
  padding: 2% 1.5% 2% 1.5%;
}

</style>
