<template>
  <div>


      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input
              @input="advanceSearch"
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :select-options="{
          enabled: false,
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">
       <span v-if="props.column.field === 'action'">
              <span class="btn-action-table">
                <b-button
                  variant="gradient-success"
                  class="btn-icon"
                  @click="addFriend(props.row._id)"
                >
                  <!-- @click="DeleteCategory(props.row._id)" -->
                  <feather-icon icon="UserPlusIcon" />
                </b-button>
              </span>
        </span>
         <span v-else-if="props.column.field === 'email'">
              <span class="btn-action-table" v-if="props.row.email">
               {{props.row.email[0]}}{{props.row.email[1]}}*********{{props.row.email[props.row.email.length-1]}}
              </span>
        </span>
            <span v-else-if="props.column.field === 'description'">
              <span class="btn-action-table" v-if="props.row.description">
               {{props.row.description}}
              </span>
                 <span class="btn-action-table" v-else>
              No description yet
              </span>
        </span>
        
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
  </div>
</template>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BForm,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BButton,
  BModal,
  VBModal,
  BCol,
  BContainer,
  BRow,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";
import { heightTransition } from "@core/mixins/ui/transition";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "axios";

export default {
  components: {
    BForm,
    BCol,
    BContainer,
    BRow,
    BButton,
    BModal,
    VBModal,
    Ripple,
    BCardActions,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdownItem,
    BDropdown,
  },
  data() {
    return {
      show: false,
      showUpdate: false,
      pageLength: 5,
      dir: false,
      columns: [
        {
          label: "Name",
          field: "name",
        },
            {
          label: "Email",
          field: "email",
        },
        {
          label: "description",
          field: "description",
        },
     
         {
          label: "City",
          field: "adresse.city",
        },
             {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: "",
    };
  },
  directives: {
    Ripple,
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: "light-primary",
        Professional: "light-success",
        Rejected: "light-danger",
        Resigned: "light-warning",
        Applied: "light-info",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  async created() {

    await this.getdata();
  },
  methods: {
     async getdata() {
      this.rows = [];
      await axios
        .get("https://backendapinodejs.timecheckit.com/api/users")
        .then((response) => {
          axios
            .get(
              "https://backendapinodejs.timecheckit.com/api/users?id=" +
                JSON.parse(localStorage.getItem("userData"))._id
            )
            .then((res) => {
              this.userInfo = res.data.data;
              console.log("dsdq", this.userInfo);
              if (response.data.data.length > 0) {
                // response.data.data.forEach((element) => {
                //   if (element.isVerified) {
                //     this.rows.push(element);
                //   }
                // });
                this.rows = response.data.data.filter(user => user.isVerified && !this.userInfo.friendList.some(friend => friend._id === user._id));

              }
            });

          // console.log(response.data.data);
          // this.rows = response.data.data;
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    },
    // getdata(){
    //    axios
    //    .get(
    //     "https://backendapinodejs.timecheckit.com/api/users"
    //   )
    //   .then((response) => {
    //     // this.rows =response.data.data
    //       if(response.data.data.length>0){
    //       response.data.data.forEach(element => {
    //         if(element.isVerified){
    //           this.rows.push(element)
    //         }
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     console.error("There was an error!", error);
    //   });
    // },
    advanceSearch(val) {
      this.searchTerm = val;
    },
    addFriend(id) {
      axios
        .post("https://backendapinodejs.timecheckit.com/api/friendRequest", {
          from: JSON.parse(localStorage.getItem("userData"))._id,
          to: id
        })
        .then((response) => {
          this.getdata();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Request send`,
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    },
  },
};
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";

@import "@core/scss/vue/libs/vue-good-table.scss";
@import "~@radial-color-picker/vue-color-picker/dist/vue-color-picker.min.css";

.btn-delete-all {
  margin-left: 3%;
}
.btn-action-table button {
  margin-left: 10px;
}
.btn-action-table {
  display: flex;
}
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
.pr-0 {
  padding-right: 0 !important;
}
.btn-table-groupe {
  float: right;
}
</style>
